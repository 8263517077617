<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">

          <div class="card-body">
            <form data-vv-scope="spreadsheet">

              <div class="row">
                <div class="col-lg-5 col-md-6 form-group">
                  <label for="month">Escolha o período do extrato</label>
                  <div>
                    <div class="row">
                      <div class="col-12">
                        <input class="pretty mt-1" type="radio" id="mesatual" name="radio-mesatual"
                          :value="true" v-model="mesAtual" @click="monthSelected(true)" :disabled="showProcessingMessage">
                        <label for="mesatual" class="ml-1">Mês atual</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <input class="pretty mt-1" type="radio" id="outroMes" name="radio-outromes"
                          :value="false" v-model="mesAtual" @click="monthSelected(false)" :disabled="showProcessingMessage">
                        <label for="outroMes" class="ml-1 mr-3">Outro mês</label>
                        <input type="month" v-model="mesSelecionado" :disabled="mesAtual || showProcessingMessage">
                      </div>
                      <div class="col-6">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!--    Actions Buttons  -->
      <div class="col-12 col-sm-12 pb-3 mb-3">
        <button
          type="button"
          class="btn btn-primary btn-fill pull-right mr-2"
          @click="onDownload"
          :disabled="showProcessingMessage">
          Download
        </button>
        <!-- <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onShow">
          Console
        </button> -->
      </div><!--    Actions Buttons  -->

    </div>

    <div class="row justify-content-md-center">
      <div v-if="showProcessingMessage" class="col-12 col-md-12">
        <div class="card card-fullheight">

          <div class="card-body">
            <h6  class="mb-0">Processando a requisição. Em breve o download iniciará.</h6>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import moment from 'moment'

import EDIService from '../../../services/EDIService'

// import CustomCheckRadio from '@/components/Forms/CustomCheckRadio.vue'

export default {
  name: 'tariffForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tariffImport') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      timer: 0,
      delay: 400,
      fullPage: false,
      disabled: false,
      today: null,
      mesAtual: true,
      mesSelecionado: '2023-09',
      meses: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      showProcessingMessage: false
    }
  },
  components: {
    Loading
    // CustomCheckRadio
  },
  beforeMount () {
    let now = new Date()
    let today = moment(now).format('YYYY-MM')

    this.today = today
    this.mesSelecionado = today
  },
  mounted () {
  },
  methods: {
    onShow () {
    },
    monthSelected (mesAtual) {
      if (mesAtual) {
        this.mesSelecionado = this.today
      }
    },
    onDownload () {
      this.isLoading = true
      this.showProcessingMessage = true

      let selectedDate = new Date(this.mesSelecionado + '-15 00:00:00')
      let month = selectedDate.getMonth()

      let filter = {
        year: selectedDate.getFullYear(),
        month: ('0' + (month + 1)).slice(-2)
      }

      EDIService.download(filter).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', `edi-${filter.year}${filter.month}.zip`)
        global.document.body.appendChild(link)
        link.click()
      }).finally(() => {
        this.isLoading = false
        this.showProcessingMessage = false
      })
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    }
  }
}
</script>

<style lang="scss" scoped>
$pretty--color-default: #84754E !default;
@import 'pretty-checkbox/src/pretty-checkbox.scss';

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}

.pretty.p-switch input:checked ~ .state.p-primary[data-v-2d98f454]:before {
  border-color: #84754E;
}

.pretty.p-switch.p-fill input:checked ~ .state.p-primary[data-v-2d98f454]:before {
  background-color: #84754E !important;
}
.swal2-popup {
  width: inherit !important;
}

.card-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.md-form-control {
  height: 30px;
}

.grey-field{
  background: #fff;
  border: none;
  color: gray;
  /* font-variant: small-caps; */
}

.grey-field .selected{
  color: gray;
  text-transform: capitalize;
}
</style>
