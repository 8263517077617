import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const EDIService = {

  /**
   * http://localhost/api/v3/edi/download
   *
   * @param data
   * @returns {Promise<any>}
   */
  download (data) {
    let queryString = httpBuildQuery(data)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/edi/download?${queryString}`, {
          responseType: 'blob'
        }).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}
export default EDIService
